import { makeAutoObservable } from 'mobx';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { notification } from 'antd';
import AppConsts from '../libs/AppConsts';
import service from '../services/UserNotificationService';
import { PagedResultDto } from '../models/PagedResultDto';
import { UserNotification } from '../models/UserNotification';
import UserNotificationState from '../enum/UserNotificationState';

export default class NotificationStore {
  data?: PagedResultDto<UserNotification>;

  item?: UserNotification;

  totalUnread?: number;

  isOpen = false;

  connection?: any;

  async getList() {
    this.data = await service.getList({ MaxResultCount: 100 });
  }

  async markAllAsRead() {
    this.read(this.data?.items.reduce((acc, notificationItem) => {
      if (notificationItem.state === UserNotificationState.unread) {
        return [...acc, notificationItem.id];
      }

      return acc;
    }, [] as string[]));
  }

  async read(initData?: string | string[]) {
    if (!initData || initData.length <= 0) {
      console.warn('NotificationStore.read; Provided data: ', initData);

      return;
    }

    const result = await service.markAsRead(initData);

    // If result successful - mark current notifications is read.
    if (result) {
      const data = typeof initData === 'string' ? [initData] : initData;

      if (this.data) {
        this.data.items = this.data.items.map((currentNotificationItem) => {
          const isTargetItem = data?.some((readenItemId) => readenItemId === currentNotificationItem.id);
          return isTargetItem ? { ...currentNotificationItem, state: UserNotificationState.read } : currentNotificationItem;
        });
      }
    } else {
      console.warn('NotificationStore.read; Request failed');
    }
  }

  async getTotalUnread() {
    const result = await service.getUnreadNumber();
    this.totalUnread = result;
  }

  async connect() {
    const tokenBody = localStorage.getItem('token')?.replace('Bearer ', '');
    this.connection = new HubConnectionBuilder()
      .withUrl(`${AppConsts.server}/messaginghub`, {
        accessTokenFactory: () => `${tokenBody}`,
      })
      .build();

    this.connection.start().then(() => {
      console.log('SignalR Connected');
      this.connection.on('message', (e: any) => {
        notification.info({ message: e });
      });
    });
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export const notificationStore = new NotificationStore();
