import { Badge } from 'antd';
import { observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import Link from 'antd/es/typography/Link';
import Route from '../../models/Route';
import IconSVG from '../IconSvg';
import Styles from '../../utils/styles';
import { useSidebarStore } from '../../Navigation';
import IconQuestions from '../../assets/icons/icon_question.svg';
import SizedBox from '../Elements/SizedBox';
import SidebarState from '../../enum/SidebarState';
import { useAuthStore, useLocale } from '../../App';
import IconDown from '../../assets/icons/icon_tab_expand.svg';
import { useGuideRefs } from '../../context/guideRefsContextType';

import styles from './index.module.css';
import mainStyles from '../../customization/customization.module.css';
import { appSettingsStore } from '../../stores/traforySettingStore';
import { getBase64Src } from '../../utils/utils';
import Loading from '../Elements/Loading';

interface Props {
  views: Route[] | undefined | null;
}

const Index = observer((props: Props) => {
  const { sideStore } = useSidebarStore();

  const { authStore } = useAuthStore();

  const [tab, setTab] = useState<string[]>([]);

  const { lang } = useLocale();
  const { guideRefs } = useGuideRefs();

  const navigate = useNavigate();
  const location = useLocation();

  // const [logo, setLogo] = useState(TraforyCore);
  const [subSelected, setSubselected] = useState('');

  const currentTab = location.pathname.split('/')[1];
  const currentSubTab = location.pathname.split('/')[2];

  const { data } = appSettingsStore;

  const goHome = () => {
    if (
      appSettingsStore.data?.homePageLink
      && !window.location.href.includes(appSettingsStore.data.homePageLink)
    ) {
      window.location.href = appSettingsStore.data?.homePageLink;
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    const setMenu = () => {
      if (sideStore.state !== SidebarState.open) {
        setTab([]);
      }
    };
    setMenu();
  }, [sideStore.state]);

  const footerTabs: Route[] = [
    {
      title: lang.CoreHr.support,
      path: 'support',
      icon: IconQuestions,
    },
  ];

  return (
    <div className={sideStore.isExpanded ? styles.sider : styles.sliderCollapsed} style={{ width: sideStore.width }}>
      <div className={styles.logo} onClick={goHome}>
        <img
          src={getBase64Src(sideStore.isExpanded ? data?.logoFileSBFullBase64 : data?.logoFileSBSmallBase64)}
        />
        <div
          className={styles.logo_hidden}
          style={{ width: sideStore.isExpanded ? 0 : 15, opacity: sideStore.isExpanded ? 0 : 1 }}
        />
      </div>
      <div className={classNames(mainStyles.h_100, mainStyles.d_flex, mainStyles.flex_column)} ref={guideRefs[6].ref}>
        <div className={styles.menu_top}>
          {
            props.views ? (
              props.views.map((view, index) => {
                const isSelected = currentTab === view.path && !view.children;

                if (!authStore.isPermissionUnlocked(view.permission)) {
                  return null;
                }

                /**
             * TODO Fully rewrite this implemetaion menu object mapping to staged cut on (view & view.children) objects.
             * (1 stage cut of all by permission, 2 stage cut of by disabled value)
             * And then just check length to decide render need
             * */
                if (view.disabled || view.children?.reduce((acc, el) => (
                  (el.disabled || !authStore.isPermissionUnlocked(el.permission)) ? acc : { ...acc, el }
                ), []).length === 0) {
                  return null;
                }

                let actions = 0;

                for (const child of view.children ?? []) {
                  if (child.circles) {
                    actions += child.circles;
                  }
                }

                if (view.circles) {
                  actions = view.circles;
                }

                return (
                  <div ref={guideRefs[index + 7]?.ref} key={view.title} className={styles.tab}>
                    <div
                      className={sideStore.isExpanded ? styles.menuItem : styles.menuItemCollapsed}
                      style={{ backgroundColor: isSelected ? Styles.primary : '' }}
                      onClick={() => {
                        if (!view.children) {
                          navigate(`${view.path}`);
                          setSubselected('');
                        } else {
                          sideStore.setOpen();
                          if (tab.includes(view.path)) {
                            const arr = tab.filter((e) => e !== view.path);
                            setTab(arr);
                          } else {
                            setTab([...tab, view.path]);
                          }
                        }
                      }}
                      role='presentation'
                    >
                      <div className={sideStore.isExpanded ? styles.menuItemContent : styles.menuItemContentSmall}>
                        <Badge dot count={actions} showZero={false}>
                          <IconSVG
                            src={view.icon}
                            color={isSelected ? 'white' : subSelected === view.path ? Styles.primary : Styles.night700}
                          />
                        </Badge>
                        <p style={{ color: isSelected ? 'white' : subSelected === view.path ? Styles.primary : '' }}>
                          {view.title}
                        </p>
                        <div
                          style={{ opacity: view.children ? 1 : 0 }}
                          className={tab.includes(view.path) ? styles.arrow_down : styles.arrow_up}
                        >
                          <IconSVG src={IconDown} color={tab.includes(view.path) ? Styles.primary : Styles.night500} />
                        </div>
                      </div>
                    </div>
                    <div
                      className={styles.children}
                      style={{ height: tab.includes(view.path) ? view.children!.length * 40 : 0 }}
                    >
                      {view.children?.map((child) => {
                        let isTabSelected = currentSubTab === child.path;

                        if (child.children) {
                          const activeSubTab = child.children.find((route) => route.path === currentSubTab);
                          if (activeSubTab) {
                            isTabSelected = true;
                          }
                        }

                        if (!authStore.isPermissionUnlocked(child.permission)) {
                          view.children = view.children?.filter((e) => e.title !== child.title);
                        }

                        if (child.disabled) {
                          view.children = view.children?.filter((e) => e.disabled !== true);
                        }

                        let alerts = 0;

                        if (child.circles) {
                          alerts = child.circles;
                          actions += child.circles;
                        }

                        const style = alerts > 0 ? { color: 'red', fontSize: 20 } : { color: isTabSelected ? 'white' : 'black' };

                        return (
                          <div
                            key={child.title}
                            className={isTabSelected ? styles.childSelected : styles.childItem}
                            onClick={() => {
                              navigate(`${view.path}/${child.path}`);
                              setSubselected(view.path);
                            }}
                            role='presentation'
                          >
                            <strong style={style}>•</strong>
                            <h5>{child.title}</h5>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })
            ) : (
              <div style={{ height: '50px' }}>
                <Loading fit />
              </div>
            )
          }
        </div>
        {/* Spacer */}
        <div className={styles.spacer} />
        {/* Footer */}
        <div className={styles.menu}>
          {footerTabs.map((view) => {
            const isSelected = currentTab === view.path;

            return (
              <div
                ref={view.title === lang.CoreHr.support ? guideRefs[4].ref : undefined}
                key={view.title}
                className={sideStore.isExpanded ? styles.menuItem : styles.menuItemCollapsed}
                style={{ backgroundColor: isSelected ? Styles.primary : '' }}
                onClick={() => {
                  navigate(view.path);
                }}
                role='presentation'
              >
                <div className={sideStore.isExpanded ? styles.menuItemContent : styles.menuItemContentSmall}>
                  <IconSVG src={view.icon} color={isSelected ? 'white' : Styles.night700} />
                  <p style={{ color: isSelected ? 'white' : '' }}>{view.title}</p>
                </div>
              </div>
            );
          })}
        </div>
        <Link className={`${styles.policyLink} ${sideStore.state !== SidebarState.open ? styles['policyLink-hidden'] : ''}`} href={appSettingsStore.data?.securityPolicyLink} target='_blank'>{lang.Trafory.personalDataProcessingPolicy}</Link>
      </div>
      <SizedBox height={18} />
    </div>
  );
});

export default Index;
