import rgbHex from 'rgb-hex';

class Styles {
  static night900 = `#${rgbHex(44, 47, 60, 1)}`;

  static night700 = `#${rgbHex(108, 114, 131, 1)}`;

  static night500 = `#${rgbHex(159, 161, 174, 1)}`;

  static night300 = `#${rgbHex(192, 195, 206, 1)}`;

  static night200 = `#${rgbHex(234, 237, 245, 1)}`;

  static night100 = `#${rgbHex(242, 243, 247, 1)}`;

  static ocean = '#3396AA';

  static mentol = '#D4F2FB';

  static yellow = '#ffc107';

  static sun = '#F1651B';

  static orange = '#b94609';

  static fury = '#dd2c00';

  static green = '#41c036';

  static blue = '#4094da';

  static purple = '#8243AF';

  static background = '#f8fbfd';

  static get primary() {
    return document.documentElement.style.getPropertyValue('--primary');
  }

  static get primary_10() {
    return document.documentElement.style.getPropertyValue('--primary_10');
  }

  static get secondary() {
    return document.documentElement.style.getPropertyValue('--secondary');
  }

  static get secondary_50() {
    return document.documentElement.style.getPropertyValue('--secondary_50');
  }
}

export default Styles;
