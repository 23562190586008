/* eslint-disable react/jsx-no-constructed-context-values */
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useMemo, useState, type FC } from 'react';
import { pdfjs } from 'react-pdf';
import { Route, Routes, useRoutes } from 'react-router-dom';
import { SidebarStoreContext } from '../../context/sidebarContextType';
import { NotificationStoreContext } from '../../context/notificationContextType';
import { notificationStore } from '../../stores/notificationStore';
import { useAuthStore } from '../../App';
import ScrollToTop from '../../utils/scrollToTop';
import SidebarStore from '../../stores/sideStore';
import { routes } from './navigation.config';

const sideStore = new SidebarStore();

export const useSidebarStore = () => useContext(SidebarStoreContext);
export const useNotificationStore = () => useContext(NotificationStoreContext);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface INavigation {
}

const Notifications = () => {
  useEffect(() => {
    const connectSignal = async () => {
      await notificationStore.getTotalUnread();
      notificationStore.connect();
    };
    connectSignal();
  }, []);

  return null;
};

const Navigation: FC<INavigation> = () => {
  const { authStore } = useAuthStore();

  const extractedRoutes = useMemo(() => authStore.getAccessableRoutes(routes), [routes, authStore.permission]);

  const router = useRoutes(extractedRoutes || []);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [isRendered, setRendered] = useState(false);

  useEffect(() => {
    authStore.getUserPermissions().then(() => {
      setRendered(true);
    });
  }, []);

  return (
    <SidebarStoreContext.Provider value={{ sideStore }}>
      <NotificationStoreContext.Provider value={{ notificationStore }}>
        <Notifications />
        {isRendered && (
          <>
            <ScrollToTop />

            {extractedRoutes ? router : (
              <Routes>
                <Route path="*" element="Loading" />
              </Routes>
            )}
            {/* <Routes>
              <Route path="*" element={<NotFoundRedirect />} />
              {authStore.isAdmin ? CoreProject : GlobaProject}
            </Routes> */}
          </>
        )}
      </NotificationStoreContext.Provider>
    </SidebarStoreContext.Provider>
  );
};

export default observer(Navigation);
