import { useState, useEffect } from 'react';
import service from './index';
import { GetCircleNotificationsDto } from './dto';
import { CircleNotificationsSummary } from '../../models/CircleNotificationsSummary';
import { useAuthStore } from '../../App';
import Pages from '../../enum/Pages';

interface Props {
  data: GetCircleNotificationsDto;
  permission?: Pages;
}

// Hook
function useGetCircles({ data, permission }: Props) {
  const [circles, setCircles] = useState<CircleNotificationsSummary>();
  const { authStore } = useAuthStore();

  useEffect(() => {
    const getData = async () => {
      const isGranted = authStore.isPermissionUnlocked(permission);
      if (isGranted) {
        const result = await service.read(data);
        setCircles(result);
      }
    };
    getData();
    return () => setCircles(undefined);
  }, []);
  return circles;
}

export default useGetCircles;
