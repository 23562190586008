interface Props {
  width?: number;
  height?: number;
}

// Удобно контролировать ширину или высоту (Идея взята из Flutter)
export default function SizedBox({ width, height }: Props) {
  return <div style={{ width, height }} />;
}

SizedBox.defaultProps = {
  width: 0,
  height: 0,
};
