export default class API {
  static Identity = class {
    static Roles = '/api/identity/roles/';

    static CustomRoleApi = '/api/roles/';

    static Users = '/api/identity/users/';
  };

  static Notification = '/api/notify/email-templates/';

  static PermissionManagement = class {
    static PermissionManagement = '/api/permissions/';
  };

  static FileManagement = class {
    static DirectoryDescriptor = '/api/file-management/directory-descriptor/';

    static FileDescriptor = '/api/file-management/file-descriptor/';
  };

  static CoreHR = class {
    static BusinessUnits = 'api/core-hr/business-units/';

    static BusinessUnitTree = 'api/core-hr/business-unit-tree/';

    static BusinessUnitType = 'api/core-hr/business-unit-types/';

    static Division = 'api/core-hr/divisions/';

    static DivisionTree = 'api/core-hr/division-tree/';

    static DivisionType = 'api/core-hr/division-types/';

    static Employee = 'api/core-hr/employees/';

    static ManagerWorkspace = 'api/trafory-manager-workspace/';

    static Job = 'api/core-hr/jobs/';

    static JobTree = 'api/core-hr/job-tree/';

    static StaffUnit = 'api/core-hr/staff-units/';

    static StaffUnitsTree = 'api/core-hr/staff-units-tree-two/';

    static Users = '/api/core-hr/users';

    static Import = '/api/core-hr/import/';

    static SearchTree = '/api/core-hr/search-tree/';

    static SelectObjectsForm = '/api/core-hr/select-objects-form/';

    static Support = '/api/core-hr/support';

    static Vacations = '/api/core-hr/vacations/';

    static TraforyTenants = 'api/trafory-tenants/';

    static Bookmark = 'api/core-hr/bookmark/';

    static BookmarkCategory = 'api/core-hr/bookmark-category/';
  };

  static HCM = class {
    static Category = '/api/hcm/categories/';

    static Competence = '/api/hcm/competences/';

    static Level = '/api/hcm/levels/';

    static Method = '/api/hcm/methods/';

    static Component = '/api/hcm/components/';

    static LevelType = '/api/hcm/level-types/';

    static Survey = '/api/hcm/surveys/';

    static Positions = '/api/hcm/positions/';

    static Evaluations = '/api/hcm/evaluations/';

    static EvaluationsResult = '/api/hcm/participant-evaluation-result/';

    static EmployeeEvaluations = 'api/hcm/employee-evaluations';

    static ProfileVersion = 'api/hcm/profiles/profile-version/';

    static Profiles = 'api/hcm/profiles/';

    static SelectObjectsForm = '/api/hcm/select-objects-form/';
  };

  static LMS = class {
    static CourseCategory = '/api/lms/course-categories/';

    static Course = '/api/lms/courses/';

    static Scorm = '/api/lms/scorm/';

    static Lesson = '/api/lms/course-lesson/';

    static Editors = '/api/lms/course-editors/';

    static LearningPath = '/api/lms/learningpaths/';

    static LearningResult = '/api/lms/learningResults/';

    static LearningPathPassing = '/api/lms/learningpathpassings/';

    static CoursePassing = '/api/lms/coursePassings/';

    static SelectObjectsForm = '/api/lms/select-objects-form/';

    static LmsGroup = '/api/lms/lms-group/';

    static LmsGroupManagers = '/api/lms/lms-group-managers/';

    static LmsEmployee = '/api/lms/lms-employees/';

    static LmsGroupEvent = '/api/lms/lms-group-events/';

    static Lookup = '/api/lms/lookups/';

    static GroupQuestion = '/api/lms/groupQuestions/';

    static GroupWorkspace = '/api/lms/Group-Workspace/';

    static ImportExport = '/api/lms/import-export/';

    static UserNotifications = '/api/notify/user-notifications/';

    static Import = '/api/core-hr/import/';

    static Support = '/api/lms/support';

    static CourseAccesses = '/api/lms/course-accesses/';

    static GeneralEducation = '/api/lms/general-education/';

    // Legacy
    static FileManagement = '/api/lms/file-management/';

    static IntentionGroups = '/api/lms/intentiongroups/';

    static Invitations = '/api/lms/invitations/';

    static CustomCertificate = '/api/lms/custom-certificate/';
  };

  static SearchTree = class {
    static SearchTree = '/api/search-tree/';
  };

  static FileManager = class {
    static lms = '/api/lms-file-management/file/';

    static core = '/api/corehr-file-management/file/';
  };

  static UsersManagement = '/api/Users-management/';

  static Blogging = '/api/blogging/';

  static Posts = '/api/blogging/posts/';

  static BloggingCategories = '/api/blogging/categories/';

  static PopularTags = '/api/blogging/tags/popular-published';

  static Comments = '/api/blogging/comments/';

  static PersonManagement = '/api/persons-management/';

  static FamilyMember = '/api/people/family-member/';
}
