import { LoadingOutlined } from '@ant-design/icons';
import Styles from '../../utils/styles';

interface Props {
  fit?: boolean;
  paddingTop?: number;
  paddingBottom?: number | string;
}
export default function Loading({ fit, paddingTop, paddingBottom }: Props) {
  return (
    <div
      style={{
        height: fit ? '100%' : '100vh',
        width: fit ? '100%' : '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 24,
        paddingTop,
        paddingBottom,
      }}
    >
      <LoadingOutlined style={{ fontSize: 28, color: Styles.primary }} />
    </div>
  );
}
