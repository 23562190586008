import http from '../../../../services/httpService';
import { AuthMethod } from '../../models/AuthMethod';

class Index {
  public async createLdap(data: any): Promise<AuthMethod> {
    const result = await http.post('/api/auth-methods/ldap', data);
    return result.data;
  }

  public async readLdap(id: string): Promise<AuthMethod> {
    const result = await http.get(`/api/auth-methods/ldap?id=${id}`);
    return result.data;
  }

  public async updateLdap(id: string, data: any): Promise<AuthMethod> {
    const result = await http.put(`/api/auth-methods/ldap?id=${id}`, data);
    return result.data;
  }

  public async remove(id: string): Promise<boolean> {
    const result = await http.delete(`/api/auth-methods?id=${id}`);
    return result.status === 200;
  }

  public async getLdapAll(): Promise<AuthMethod[]> {
    const result = await http.get('/api/auth-methods/ldap/all');
    return result.data;
  }

  public async getPasswordAll(): Promise<AuthMethod[]> {
    const result = await http.get('/api/auth-methods/password/all');
    return result.data;
  }

  public async readPassword(id: string): Promise<AuthMethod> {
    const result = await http.get(`/api/auth-methods/password?id=${id}`);
    return result.data;
  }

  public async updatePassword(id: string, data: any): Promise<AuthMethod> {
    const result = await http.put(`/api/auth-methods/password?id=${id}`, data);
    return result.data;
  }

  public async getTypesCombobox(): Promise<AuthMethod[]> {
    const result = await http.get('/api/auth-methods/all');
    return result.data;
  }
}

export default new Index();
