import axios from 'axios';
import AppConsts from '../../libs/AppConsts';
import { Localization } from '../../models/AppConfig';
import http from '../httpService';
import { SetLanguageDto } from './dto';

class Index {
  public async setLanguage(e: SetLanguageDto) {
    await http.get('/Abp/Languages/Switch', { params: e });
    window.location.reload();
  }

  public async getLocalization(): Promise<Localization> {
    const locale = localStorage.getItem('Locale') ?? 'en';
    const result = await axios.get(`${AppConsts.server}/api/abp/application-localization?CultureName=${locale}`);
    return result.data;
  }
}

export default new Index();
