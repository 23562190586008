import IconBack from '../../assets/icons/icon_expand_left.svg';
import IconSVG from '../IconSvg';
import styles from './styles/BackButton.module.css';

type TSize = 'small' | 'middle';
interface Props {
  className?: string;
  onClick: () => void;
  size?: TSize;
}

export default function BackButton(props: Props) {
  const { className, onClick, size = 'middle' } = props;
  const value = size === 'middle' ? '32px' : '24px';

  return (
    <div className={className} onClick={onClick} style={{ width: value, height: value }}>
      <div className={styles.back}>
        <IconSVG src={IconBack} type="primary" />
      </div>
    </div>
  );
}
