import { TraforyResetSettings, TraforySetting } from '../../models/TraforySetting';
import http from '../httpService';

class TraforySettingService {
  public async read() {
    const result = await http.get('/api/trafory-settings');
    return result.data;
  }

  public async update(e: TraforySetting | TraforyResetSettings) {
    try {
      const result = await http.post('/api/trafory-settings', e);
      return result.status === 200;
    } catch (err) {
      console.error(err);
    }
  }
}

export default new TraforySettingService();
