import { PagedFiltedDto } from '../../../../models/PagedFilteredDto';
import { PagedResultDto } from '../../../../models/PagedResultDto';
import { User } from '../../../../models/User';
import API from '../../../../services/Api';
import http from '../../../../services/httpService';
import { RoleCoreHr } from '../../models/Role_CoreHr';
import {
  CreateUserDto, LockUserDto, SendPasswordResetDto, UpdateUserDto,
} from './dto';

class Index {
  async getPagedList(e: PagedFiltedDto): Promise<PagedResultDto<User>> {
    const result = await http.get(API.Identity.Users, { params: e });
    return result.data;
  }

  async create(e: CreateUserDto): Promise<User> {
    const result = await http.post(API.Identity.Users, e);
    return result.data;
  }

  async read(id?: string): Promise<User> {
    const result = await http.get(API.Identity.Users + id);
    return result.data;
  }

  async update(e: UpdateUserDto, id?: string): Promise<User> {
    const result = await http.put(API.Identity.Users + id, e);
    return result.data;
  }

  async delete(id?: string) {
    const result = await http.delete(API.Identity.Users + id);
    return result.status === 200;
  }

  async getUserRoles(id?: string): Promise<RoleCoreHr[]> {
    const result = await http.get(`${API.Identity.Users}${id}/roles`);
    return result.data.items;
  }

  async getAvailableRoles(): Promise<RoleCoreHr[]> {
    const result = await http.get(`${API.Identity.Users}lookup/roles`);
    return result.data;
  }

  async lockUser(e: LockUserDto) {
    await http.put(`${API.Identity.Users}${e.id}/lock/${e.lockoutEnd.toISOString()}`);
  }

  async unlockUser(id: string) {
    await http.put(`${API.Identity.Users}${id}/unlock`);
  }

  async sendPassowordReset(e: SendPasswordResetDto) {
    await http.post('/api/passwords-management/send-password-reset-code', e);
  }
}

export default new Index();
