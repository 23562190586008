import { observer } from 'mobx-react';
import { Button, Form, Input, notification } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Link from 'antd/es/typography/Link';
import { useLocale } from '../../App';
import http from '../../services/httpService';
import Language from '../components/Language';
import SizedBox from '../../components/Elements/SizedBox';
import { validatePassword } from '../../utils/passwordUtils';
import PasswordValidator from '../../components/Elements/PasswordValidator';
import { appSettingsStore } from '../../stores/traforySettingStore';
import loginStyles from '../Login/index.module.css';
import { getBase64Src } from '../../utils/utils';

import styles from './index.module.css';
import PhonesBar from '../../components/Phones';

interface Output {
  tenant: string;
}

const Index = observer(() => {
  const { lang } = useLocale();

  const [searchParams] = useSearchParams();

  const [isLoading, setLoading] = useState(false);

  const [dto, setDto] = useState({
    userId: '',
    password: '',
    resetToken: '',
  });

  const [repeatPassword, setRepeatPassword] = useState('');

  const nav = useNavigate();

  const { userId, resetToken, __tenant } = useMemo(() => ({
    userId: searchParams.get('userId'),
    resetToken: searchParams.get('resetToken'),
    __tenant: searchParams.get('__tenant'),
  }), [searchParams]);

  useEffect(() => {
    if (userId && resetToken) {
      setDto({ userId: userId || '', password: '', resetToken: resetToken || '' });
    }
  }, [__tenant, userId, resetToken]);

  const resetPassword = async () => {
    setLoading(true);
    try {
      const result = await http.post('/api/passwords-management/reset-password', dto, {
        headers: {
          __tenant,
        },
      });
      if (result.status === 200) {
        const data = result.data as Output;
        localStorage.setItem('tenancyName', data.tenant);
        notification.success({ message: lang.Lms.passwordUpdated });
        nav(`/?tenant=${data.tenant}`, { replace: true });
      }
    } finally {
      setLoading(false);
    }
  };

  const isValid = repeatPassword === dto.password && validatePassword(dto.password);

  return (
    <div className={styles.area}>
      {appSettingsStore.data?.authCoverPicBase64 ? (
        <div
          style={{ backgroundImage: `url(${getBase64Src(appSettingsStore.data?.authCoverPicBase64)})` }}
          className={loginStyles.cover}
        />
      ) : <PhonesBar />}
      <div className={styles.body}>
        <div className={loginStyles.header}>
          <p>{lang.Lms.enterTo}</p>
          <img
            src={getBase64Src(appSettingsStore.data?.logoFileBigBase64)}
            className={loginStyles.logo}
          />
        </div>
        <Form layout='vertical' className={styles.form} labelAlign='left' labelCol={{ span: 8 }}>
          <h2>{lang.Lms.password}</h2>
          <Input.Password
            value={dto.password}
            onChange={(e) => setDto({ ...dto, password: e.target.value })}
            type='password'
            size='large'
            placeholder={lang.Lms.enterPassword}
            className={!validatePassword(dto.password) && dto.password.length > 1 ? 'input-password-error' : ''}
          />
          <SizedBox height={16} />
          <h2>{lang.Lms.repeatPassword}</h2>
          <Input.Password
            value={repeatPassword}
            onChange={(e) => {
              setRepeatPassword(e.target.value);
            }}
            type='password'
            size='large'
            placeholder={lang.CoreHr.reenterPassword}
            disabled={!validatePassword(dto.password)}
            className={repeatPassword === dto.password ? '' : styles.error}
          />
          <SizedBox height={24} />
          <PasswordValidator text={dto.password} />
          <SizedBox height={24} />
          <div className={styles.proceed}>
            <Button type="primary" disabled={!isValid || isLoading} onClick={resetPassword}>
              {lang.CoreHr.proceed}
            </Button>
          </div>
        </Form>
        <Link className={styles.policyLink} href='https://www.trafory.com/trafory-policy' target='_blank'>Политика обработки персональных данных</Link>
      </div>
      <Language />
    </div>
  );
});

export default Index;
