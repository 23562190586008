import AppConsts from '../../libs/AppConsts';
import { AppConfig } from '../../models/AppConfig';
import http from '../httpService';
import { CheckTenantDto, LoginDto, TokenDto } from './dto';
import { UserPermission } from '../../models/UserPermission';
import { deleteAllCookies } from '../../utils/cookies';

class Index {
  public async getAppConfig(): Promise<AppConfig> {
    const result = await http.get('/api/abp/application-configuration?IncludeLocalizationResources=false');
    return result.data;
  }

  public async getUserPermissions(): Promise<UserPermission> {
    const result = await http.get('/api/permissions/current-permissions');
    return result.data;
  }

  public async checkTenant(e: string): Promise<CheckTenantDto> {
    const result = await http.get(`${AppConsts.server}/api/trafory-tenants/by-name-case-insensitive/${e}`);
    return result.data;
  }

  public async checkTenantById(e: string): Promise<CheckTenantDto> {
    const result = await http.get(`${AppConsts.server}/api/trafory-tenants/${e}`);
    return result.data;
  }

  public async getAuthToken(data: LoginDto, tenantId?: string): Promise<TokenDto> {
    const result = await http.post(`${AppConsts.server}/auth/token`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        ...(tenantId ? { __tenant: tenantId } : {}),
      },
    });
    return result.data;
  }

  public async getToken(e: LoginDto, tenantId: string): Promise<TokenDto> {
    const result = await http.post(`${AppConsts.server}/connect/token`, e, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        __tenant: tenantId,
      },
    });
    return result.data;
  }

  public logout() {
    localStorage.clear();
    deleteAllCookies();
  }
}

export default new Index();
