import { CircleNotificationsSummary } from '../../models/CircleNotificationsSummary';
import http from '../httpService';
import { GetCircleNotificationListDto, GetCircleNotificationsDto } from './dto';

class CircleNotificationService {
  public async read(e: GetCircleNotificationsDto): Promise<CircleNotificationsSummary> {
    const result = await http.get('/api/notify/circle-notifications', { params: e });
    return result.data;
  }

  public async getList(e: GetCircleNotificationListDto): Promise<CircleNotificationsSummary[]> {
    const result = await http.post('/api/notify/circle-notifications/list', e);
    return result.data;
  }
}

export default new CircleNotificationService();
