import { Space } from 'antd';
import { FC } from 'react';
import { useLocale } from '../../App';
import styles from './index.module.css';
import Utils from '../../utils/utils';

interface ILoginPictureStub {
  className?: string
}

const LoginPictureStub: FC<ILoginPictureStub> = ({ className: providedClassName }) => {
  const { lang } = useLocale();

  // const getios = () => Utils.openInNewTab('https://apps.apple.com/by/app/trafory-business/id1541718498');
  // const getAndroid = () => Utils.openInNewTab('https://play.google.com/store/apps/details?id=by.dots.trafory_app');
  const openLinkedIn = () => Utils.openInNewTab('https://www.linkedin.com/company/trafory/?viewAsMember=true&original_referer=');
  const openInstagram = () => Utils.openInNewTab('https://instagram.com/trafory_com?igshid=YmMyMTA2M2Y=');

  const handlePhoneCall = () => {
    const phoneNumber = '+375333272153';
    const phoneUrl = `tel:${phoneNumber}`;
    window.location.href = phoneUrl;
  };

  return (
    <div className={`${styles.cover} ${providedClassName || ''}`}>
      <div className={styles.logo} />
      <h5>{lang.CoreHr.completeSolution}</h5>
      <p>{lang.CoreHr.mainSlogan}</p>
      <div className={styles.phones} />
      <Space size={40}>
        <div className={styles.phone} onClick={handlePhoneCall} />
        <div className={styles.linkedin} onClick={openLinkedIn} />
        <div className={styles.instagram} onClick={openInstagram} />
      </Space>
      {/* <h4>{lang.Lms.installTraforyApp}</h4>
      <Space size={16}>
        <div className={styles.apple} onClick={getios} />
        <div className={styles.google} onClick={getAndroid} />
      </Space> */}
    </div>
  );
};

export default LoginPictureStub;
