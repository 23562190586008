import { useLocale } from '../../App';
import styles from './styles/PasswordValidator.module.css';

interface Props {
  text?: string;
}

type PasswordValidationResult = {
  minLength: boolean;
  hasLowercase: boolean;
  hasUppercase: boolean;
  hasSpecialCharacter: boolean;
};

const validatePassword = (password: string): PasswordValidationResult => ({
  minLength: password.length >= 8,
  hasLowercase: /[a-z]/.test(password),
  hasUppercase: /[A-Z]/.test(password),
  hasSpecialCharacter: /[~!?#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?@]+/.test(password),
});

export default function PasswordValidator({ text }: Props) {
  const { lang } = useLocale();

  const validationResult = validatePassword(text ?? '');

  return (
    <div className={styles.requirementsToPassword}>
      <p>{lang.Lms.requirementsToPassword}</p>
      <ul>
        <li style={{ textDecoration: validationResult.minLength ? 'line-through' : 'none' }}>
          {lang.Lms.eightOrMoreCharacters}
        </li>
        <li style={{ textDecoration: validationResult.hasLowercase ? 'line-through' : 'none' }}>
          {lang.Lms.atLeastOneLowercaseLetter}
        </li>
        <li style={{ textDecoration: validationResult.hasUppercase ? 'line-through' : 'none' }}>
          {lang.Lms.atLeastOneCapitalLetter}
        </li>
        <li style={{ textDecoration: validationResult.hasSpecialCharacter ? 'line-through' : 'none' }}>
          {lang.Lms.atLeastOneNonLetterCharacted}
        </li>
      </ul>
    </div>
  );
}
