import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const originalParse = dayjs.parse;
dayjs.parse = (cfg) => originalParse(cfg, { zoned: false });

dayjs.tz.setDefault(null); // Disable timezone conversion
