import { createContext } from 'react';
import SidebarStore from '../stores/sideStore';

export type SidebarContextType = {
  sideStore: SidebarStore;
};

export const SidebarStoreContext = createContext<SidebarContextType>({
  sideStore: new SidebarStore(),
});
