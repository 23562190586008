import { Drawer, Typography } from 'antd';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useLocale } from '../../App';
import { useNotificationStore } from '../../Navigation';
import Utils from '../../utils/utils';
import styles from './index.module.css';
import IconGroup from '../../assets/icons/icon_group.svg';
import IconSVG from '../IconSvg';
import { UserNotification } from '../../models/UserNotification';
import UserNotificationState from '../../enum/UserNotificationState';
import Styles from '../../utils/styles';

const Index = observer(() => {
  const { notificationStore } = useNotificationStore();
  const { lang } = useLocale();

  const close = () => {
    notificationStore.data = undefined;
  };

  useEffect(() => {
    const markAllRead = async () => {
      if (notificationStore.data) {
        await notificationStore.markAllAsRead();
      }
    };
    markAllRead();
  }, [notificationStore.data]);

  return (
    <Drawer
      width={400}
      title={lang.Lms.notifications}
      open={notificationStore.data !== undefined}
      closable
      onClose={close}
      bodyStyle={{ padding: 10 }}
    >
      <div className={styles.boxes}>
        {notificationStore.data?.items?.map((item) => (
          <Label item={item} key={item.id} />
        ))}
      </div>
    </Drawer>
  );
});

export default Index;

function Label({ item }: { item: UserNotification }) {
  const { notificationStore } = useNotificationStore();

  const isUnread = item.state === UserNotificationState.unread;

  const openLink = async () => {
    await notificationStore.read(item.id);
    if (item.userNotificationInfo.link !== null) {
      window.location.href = item.userNotificationInfo.link;
    }
  };

  const label = (value: string[] | string) => {
    if (value === null) {
      return '';
    }
    if (typeof value === 'string') {
      return <div className={styles.title} style={{ fontWeight: isUnread ? 500 : 400 }}>{value}</div>;
    }
    return (
      <div className={styles.title} style={{ fontWeight: isUnread ? 500 : 400 }}>
        {`${[value[0]]} `}
        <span style={{ color: Styles.primary }}>{`${[value[1]] ?? ''}`}</span>
      </div>
    );
  };

  return (
    <div className={classNames(styles.box, isUnread ? styles.unread : '')} key={item.id} onClick={openLink}>
      <div className={styles.square}>
        <IconSVG src={IconGroup} type='primary' />
      </div>
      <div className={styles.body}>
        <Typography.Paragraph ellipsis={{ rows: 2 }}>{item.userNotificationInfo.titleArray ? label(item.userNotificationInfo.titleArray) : label(item.userNotificationInfo.title)}</Typography.Paragraph>
        <h4>{Utils.getDate('dateLabel', item.userNotificationInfo.creationTime, undefined, true)}</h4>
      </div>
    </div>
  );
}
