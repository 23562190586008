// *
// *
// *
// *
// *
// *
// *  THIS FILE IS DEPRECATED
// *
// *
// *
// *
// *
// *
// *
// *
/* eslint-disable @typescript-eslint/no-unused-vars */
// import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import { pdfjs } from 'react-pdf';
// import { observer } from 'mobx-react';
// import SidebarStore from './stores/sideStore';
// import ScrollToTop from './utils/scrollToTop';
// import { useAuthStore } from './App';
// import { notificationStore } from './stores/notificationStore';
import { useContext } from 'react';
import { SidebarStoreContext } from './context/sidebarContextType';
import { NotificationStoreContext } from './context/notificationContextType';

// const sideStore = new SidebarStore();

export const useSidebarStore = () => useContext(SidebarStoreContext);
export const useNotificationStore = () => useContext(NotificationStoreContext);

// const Notifications = () => {
//   useEffect(() => {
//     const connectSignal = async () => {
//       await notificationStore.getTotalUnread();
//       notificationStore.connect();
//     };
//     connectSignal();
//   }, []);

//   return null;
// };

// const Navigation = observer(() => {
//   pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

//   const { authStore } = useAuthStore();

//   const [isRendered, setRendered] = useState(false);

//   useEffect(() => {
//     authStore.getUserPermissions().then(() => {
//       setRendered(true);
//     });
//   }, []);

//   // const NotFoundRedirect = () => {
//   //   // location.pathname = '';
//   //   console.error('wrong path˝');
//   //   return <div />;
//   // };

//   return (
//     <SidebarStoreContext.Provider value={{ sideStore }}>
//       {/* <NotificationStoreContext.Provider value={{ notificationStore }}>
//         <Notifications />
//         {isRendered && (
//           <BrowserRouter>
//             <ScrollToTop />
//             <Routes>
//               <Route path="*" element={<NotFoundRedirect />} />
//               {authStore.isAdmin ? CoreProject : GlobaProject}
//             </Routes>
//           </BrowserRouter>
//         )}
//       </NotificationStoreContext.Provider> */}
//     </SidebarStoreContext.Provider>
//   );
// });

// export default Navigation;
