// import { Modal } from 'antd';
import axios, { type AxiosInstance } from 'axios';
import AppConsts from '../libs/AppConsts';
import Lang from '../utils/lang/lang';
import showAlertDialog from '../utils/showAlertDialog';
import { AuthStoreIns } from '../context/authContextType';

const http: AxiosInstance & {
  cancelRequest?: () => void
} = axios.create({
  baseURL: AppConsts.server,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Accept-Language': Lang.locale,
  },
});

// Обработчик ошибок
http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.code === 'ERR_CANCELED') {
      return error;
    }
    // Custom response from beckend, created by Ivan

    if (error?.response?.status === 401) {
      AuthStoreIns.logout();
    } else {
      showAlertDialog({
        content: error.response.data.error?.message,
        title: error.response.data?.error?.data?.Title?.value,
      });
    }
    return Promise.reject(error);
  },
);

// Request interceptors for API calls
http.interceptors.request.use(
  async (config) => {
    const tenancyName = localStorage.getItem('tenancyName');
    const tenantId = localStorage.getItem('tenantId');
    config.headers.Authorization = localStorage.getItem('token');
    config.headers['Accept-Language'] = Lang.locale;

    if (tenancyName || tenantId) {
      config.headers.__tenant = tenantId || tenancyName;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

export default http;
