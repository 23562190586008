import { makeAutoObservable } from 'mobx';
import { createContext, useContext } from 'react';
import service from '../services/TraforySettingService';
import { TraforySetting } from '../models/TraforySetting';
import Utils from '../utils/utils';

export default class TraforySettingStore {
  data?: TraforySetting;

  get entryLogo() {
    return this.data?.logoFileBigBase64;
  }

  async read() {
    this.data = await service.read();
  }

  async update() {
    if (this.data) {
      await service.update(this.data);
      await this.read();
    }
  }

  async resetDesign() {
    await service.update({
      primaryColor: null,
      secondaryColor: null,
      linksColor: null,
      homePageLink: null,
      logoFileBigId: crypto.randomUUID(),
      logoFileSBSmallId: crypto.randomUUID(),
      logoFileSBFullId: crypto.randomUUID(),
    });
    await this.read();
  }

  async updateStyles() {
    await this.read();
    Utils.setStyles(this.data!.primaryColor, this.data!.secondaryColor, this.data!.linksColor, this.data!.defaultFont);
  }

  constructor() {
    makeAutoObservable(this);
  }
}

const TraforySettingStoreContext = createContext({ store: new TraforySettingStore() });

export const useTraforySettingStore = () => useContext(TraforySettingStoreContext);

export const appSettingsStore = new TraforySettingStore();
